<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1">
      <div class="d-flex justify-space-between">
        <p class="font-weight-bold title black--text mb-1">Paket Influencer</p>
        <p @click="openAll()" class="primary--text text-14 pt-1 font-weight-bold mb-1 pointer">Lihat Lainnya</p>
      </div>
      <v-row v-if="isLoading" no-gutters class="d-flex justify-space-between">
        <v-col cols="12" v-for="n in 2" :key="n">
          <v-card
            class="round"
            elevation="0 mb-3"
            outlined
          >
            <v-skeleton-loader
              type="article"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
      <div v-else>
        <div v-for="list in listPackages" :key="list.id">
          <v-row class="d-flex justify-space-between pt-3 pb-2">
            <v-col cols="4" class="pr-0">
              <v-img @click="openDetail(list.id)" width="100" height="100" class="round" :src="list.photo"></v-img>
            </v-col>
            <v-col>
              <p @click="openDetail(list.id)" v-if="list.name.length < 22" class="left-text black--text font-weight-bold mb-1">{{ list.name }}</p>
              <p @click="openDetail(list.id)" v-else class="left-text black--text font-weight-bold mb-1">{{list.name.substring(0,22)+".."}}</p>
              <p class="font-weight-semibold text-12"><i class="fas fa-tag mr-1"></i> IDR {{ list.price | numberFormat }}</p>
              <v-btn @click="openDetail(list.id)" elevation="0" rounded class="text-capitalize mb-3" small color="primary">Lihat Detail</v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
      </div>
    </div>

    <!-- DESKTOP -->
    <div v-else>
      <v-card class="round" elevation="0">
        <v-card-title class="pb-0 pt-3 d-flex justify-space-between">
          <p class="title font-weight-bold black--text mb-0">Paket Influencer</p>
          <p @click="openAll()" class="primary--text text-14 pt-1 font-weight-bold pointer">Lihat Lainnya</p>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-row v-if="isLoading" no-gutters class="d-flex justify-space-between pl-2 pr-2">
            <v-col cols="3" v-for="n in 4" :key="n">
              <v-card
                height="280px"
                class="round ma-3"
                elevation="0"
                outlined
              >
                <v-skeleton-loader
                  type="card"
                ></v-skeleton-loader>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else no-gutters class="d-flex justify-space-between pl-2 pr-2">
            <v-col cols="3" v-for="list in listPackages" :key="list.id">
              <v-card
                height=""
                class="round ma-3"
                elevation="0"
                outlined
              >
                <v-img @click="openDetail(list.id)" class="pointer" height="200" :src="list.photo"></v-img>
                <v-card-text class="pa-2 pb-0 black--text">
                  <p @click="openDetail(list.id)" v-if="list.name.length < 30" class="left-text black--text font-weight-bold mb-1 pointer">{{ list.name }}</p>
                  <p @click="openDetail(list.id)" v-else class="left-text black--text font-weight-bold mb-1 pointer">{{list.name.substring(0,30)+".."}}</p>
                  <p class="font-weight-semibold mb-1"><i class="fas fa-tag mr-1"></i> IDR {{ list.price | numberFormat }}</p>
                  <v-btn @click="buy(list.name)" elevation="0" block rounded class="text-capitalize mb-3" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Buy Package</v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      isMobileWidth:0,
      listPackages:[]
    }
  },
  mounted(){
    this.loadPackages()

    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    loadPackages(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'packages/getPackagesHome'
        ).then(response => {
          if(response){
            let data = response.data;
            let limit
            if(this.isMobileWidth === 1){
              limit = 3
            } else {
              limit = 4
            }
            for(let a=0; a < limit; a++){
              axios({
                // url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                url: process.env.VUE_APP_API_ENDPOINT + 'packages/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
              this.listPackages.push(data[a])
            }
            setTimeout(() => {
              this.isLoading = false
              this.loadingTable = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    buy(title){
      window.open('https://wa.me/6285691701929?text=Halo Admin, Saya tertarik untuk order paket *'+title+'*. Mohon dibantu proses ya. \n Terima kasih', '_blank');
    },
    openDetail(id){
      this.$router.push({name:'package-detail', query: {item: this.encryptData(id)}});
    },
    openAll(){
      this.$router.push({name:'package-all'});
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>
